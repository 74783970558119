/* You can add global styles to this file, and also import other style files */
@import "bootstrap/scss/bootstrap";
@import "@intc/dlux-bootstrap/dist/css/dlux.css";
@import "./styles/globals/variables.scss";
@import "@progress/kendo-theme-bootstrap/dist/all.scss";
@import "./styles/globals/globals.scss";

.main-content {
    margin: 20px;
    height: 90vh;
  }

   /* Loader Panel Styles */
   .k-loading-panel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 100px;
    position: absolute;
    margin:auto;
/*     top: 0;
    left: 0; */
    z-index: 1000000;
  }
  .k-loading-panel-mask {
    width: 200px;
    height: 100px;
    position: absolute;
    margin: auto;
    /* top: 0;
    left: 0; */
    background-color: #000000;
    opacity: 0.8;
  }
  .k-loading-panel-wrapper {
    position: relative;
    z-index: 2;
  }

  .k-loading-panel-text {
    margin: auto;
    text-align: center;
    color: #ffffff;
  }
